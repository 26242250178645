import './App.css';
import {DataStore} from 'aws-amplify';
import {SiteByCpcode} from './models';
import {useEffect, useMemo, useState} from "react";
import {cacheClearByCpcode} from "./utils";

function App() {

    const [cpcodes, setCpcodes] = useState([]);
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [activityLog, setActivityLog] = useState([]);

    const sendUpdateToActivityLog = (message, type) => {
        setActivityLog(activityLog => ([
            ...activityLog,
            {
                message: message,
                timestamp: new Date(),
                logType: type
            }]));
    }

    const requestCacheClear = (cpcode, name) => {
        setRequestInProgress(true);
        sendUpdateToActivityLog(`Cache clear requested for ${name}`, 'info', 'Staging');
        cacheClearByCpcode([cpcode], 'Production', (response) => {
            if (response && (response.httpStatus === 201 || response.httpStatus === 200)) {
                sendUpdateToActivityLog(`Cache clear successful!`, 'success');
            } else {
                sendUpdateToActivityLog(`Issue with cache clear.`, 'error');
            }
            setRequestInProgress(false);
        })

    }

    useEffect(() => {
        if(cpcodes.length === 0) {
            DataStore.query(SiteByCpcode)
                .then(response => setCpcodes(response))
                .catch(error => console.error(error))
                .finally(() => console.log('Data store access attempt complete'));
        }
    }, [cpcodes]);

    const cpcodesMemoized = useMemo(() => cpcodes, [cpcodes]);

    const cpcodeListing = cpcodesMemoized.map((cpcodeObj) => {
            const name = cpcodeObj.name;
            const cpcode = cpcodeObj.cpcode;
            return <li className='cpcode' key={cpcodeObj.id}>
                <h3>{name}</h3>
                <p className={'cpcode-cpcode'}>CP Code: {cpcode}</p>
                <button className={'clear-button'}
                        onClick={() => {
                            if (!requestInProgress) {
                                requestCacheClear(cpcode, name)
                            }
                        }}
                        disabled={requestInProgress}

                >Invalidate Cache
                    on Production Network
                </button>
            </li>;
        }
    )

    return <>
        <main>
            <header>
                <h1>IH Akamai Cache Clear Utility</h1>
            </header>
            <div className="app-container">
                <div className="cache-section">
                    <h2>Clear by CP Code</h2>
                    <p>Invalidate cache for all web pages related to a web property related to a specific CP Code,
                        usually
                        for one site, or a collection of sites.</p>
                    {cpcodes.length > 0 ? <ul className={'cpcode-list'}>
                        {cpcodeListing}
                    </ul> : <p>Attempting to load sites, if nothing loads refresh the page...</p>}
                </div>
                <div className="activity-log">
                    {activityLog.map((logEntry, index) => {
                        // make timestamp human readable
                        const timestampReadable = logEntry.timestamp.toLocaleString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        });
                        return <p className={logEntry.logType}
                                  key={index}>{timestampReadable} - {logEntry.message}</p>
                    })}
                </div>
            </div>
        </main>
    </>;
}

export default App;
