// wrap API.post() in a function so we can call it from a button
import {API} from "aws-amplify";

export const cacheClearByCpcode = (cpcodes, network = 'Production', callback, testing = false) => {
    if(testing){
        console.log('debugging! no request sent');
        callback({
            httpStatus: 400,
            detail: "Request accepted",
            supportId: "eicp-2GLQypg6onBMyEuNS5vWTT",
            purgeId: "eicp-2GLQypg6onBMyEuNS5vWTT",
            estimatedSeconds: 5
        });
        return;
    }

    const apiName = 'cacheClearApi';
    const path = '/clear';
    const defaultNetwork = network;
    const cacheClearRequest = {
        body: {}, // replace this with attributes you need
        // response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        headers: {} // OPTIONAL
    };

    cacheClearRequest.body = {
        cpcodes: cpcodes,
        network: defaultNetwork
    }

    API.post(apiName, path, cacheClearRequest)
        .then((response) => {
            console.info('Success',response);
            callback(response);
            // return response;
        })
        .catch((error) => {
            console.error('Error:', error.response);
            callback(error.response);
            // return(error.response);
        });
}